import bs58 from "bs58";
const HEX_PREFIX = "41";

const hexAddressToBase58 = (hexAddress) => {
  let retval = hexAddress;
  try {
    if (hexAddress.startsWith("0x")) {
      hexAddress = HEX_PREFIX + hexAddress.substring(2);
    }
    let bArr = window.tronWeb.utils["code"].hexStr2byteArray(hexAddress);
    retval = window.tronWeb.utils["crypto"].getBase58CheckAddress(bArr);
  } catch (e) {
    //Handle
  }
  return retval;
};
const getBalance = async () => {
  //if wallet installed and logged , getting TRX token balance
  if (window.tronWeb && window.tronWeb.ready) {
    let walletBalances = await window.tronWeb.trx.getAccount(
      window.tronWeb.defaultAddress.base58
    );
    return walletBalances;
  } else {
    return 0;
  }
};

export const getWalletDetails = async () => {
  if (window.tronWeb) {
    //checking if wallet injected
    if (window.tronWeb.ready) {
      let tempBalance = await getBalance();
      let tempFrozenBalance = 0;

      if (!tempBalance.balance) {
        tempBalance.balance = 0;
      }

      //checking if any frozen balance exists
      if (
        !tempBalance.frozen &&
        !tempBalance.account_resource?.frozen_balance_for_energy
      ) {
        tempFrozenBalance = 0;
      } else {
        if (
          tempBalance.frozen &&
          tempBalance.account_resource.frozen_balance_for_energy
        ) {
          tempFrozenBalance =
            tempBalance.frozen[0].frozen_balance +
            tempBalance.account_resource.frozen_balance_for_energy
              .frozen_balance;
        }
        if (
          tempBalance.frozen &&
          !tempBalance.account_resource.frozen_balance_for_energy
        ) {
          tempFrozenBalance = tempBalance.frozen[0].frozen_balance;
        }
        if (
          !tempBalance.frozen &&
          tempBalance.account_resource.frozen_balance_for_energy
        ) {
          tempFrozenBalance =
            tempBalance.account_resource.frozen_balance_for_energy
              .frozen_balance;
        }
      }

      return {
        msg: "connected",
        name: window.tronWeb.defaultAddress.name,
        address: window.tronWeb.defaultAddress.base58,
        trxAddress: hexAddressToBase58(tempBalance.address),
        balance: tempBalance.balance / 1000000,
        frozenBalance: tempFrozenBalance / 1000000,
        network: window.tronWeb.fullNode.host,
        link: "true",
      };
    } else {
      return {
        msg: "detected",
        name: "none",
        address: "none",
        trxAddress: "none",
        balance: 0,
        frozenBalance: 0,
        network: "none",
        link: "false",
      };
    }
  } else {
    return {
      msg: "none",
      name: "none",
      address: "none",
      trxAddress: "none",
      balance: 0,
      frozenBalance: 0,
      network: "none",
      link: "false",
    };
  }
};
