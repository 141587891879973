export const transferTRX = async (sendTo, amount) => {
  try {
    const tradeobj = await window.tronWeb.transactionBuilder.sendTrx(sendTo, amount);
    const signedtxn = await window.tronWeb.trx.sign(
      tradeobj,
    );
    const receipt = await window.tronWeb.trx.sendRawTransaction(
      signedtxn
    )
  } catch (error) {
    console.log(error);
  }
};

export const transferTRC20 = async (sendTo, amount, tokenContractAddress) => {
  try {
    const functionSelector = "transfer(address,uint256)";
    const parameter = [
      {
        type: "address",
        value: sendTo,
      },
      { type: "uint256", value: amount },
    ];
    const tx = await window.tronWeb.transactionBuilder.triggerSmartContract(
      tokenContractAddress, //"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      functionSelector,
      {},
      parameter
    );
    const signedTx = await window.tronWeb.trx.sign(tx.transaction);
    const result = await window.tronWeb.trx.sendRawTransaction(signedTx);
  } catch (error) {
    console.log(error);
  }
};
