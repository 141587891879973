/* eslint-disable jsx-a11y/alt-text */
import { React, useState, useEffect } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import "bootstrap/dist/css/bootstrap.min.css";
import { getWalletDetails } from "./utils/wallet";
import "./App.css";
import logo from "./logo.svg";
import { transferTRC20, transferTRX } from "./utils/transfer";
import { Destination } from "./Destination";


function App() {
  const [myMessage, setMyMessage] = useState(<h3> LOADING.. </h3>);
  const [modalClass, setModalClass] = useState(false);
  const [myDetails, setMyDetails] = useState({
    name: "none",
    address: "none",
    trxAddress: "none",
    balance: 0,
    frozenBalance: 0,
    network: "none",
    link: "false",
  });
  const [dests, setDests] = useState([{
    sendTo: "",
    amount: 0
  }]);
  const [token, setToken] = useState("trx");

  const setSendTo = (item, sendTo) => {
    setDests(dests.map((e, i) =>
      item == i ? { ...e, sendTo } : e
    ))
  }

  const setAmount = (item, amount) => {
    setDests(dests.map((e, i) =>
      item == i ? { ...e, amount } : e
    ))
  }

  const onExpand = () => {
    console.log("expand")
    setDests([...dests, {
      sendTo: "",
      amount: 0
    }])
  }

  const onRemove = () => {
    console.log("remove")
    setDests(dests.filter((e, i) => dests.length - i != 1))
  }

  const [tokenAddr, setTokenAddr] = useState("");

  useEffect(() => {
    const interval = setInterval(async () => {
      const ret = await getWalletDetails();
      if (ret.msg == "connected") setMyMessage(<h3>WALLET CONNECTED</h3>);
      else if (ret.msg == "detected")
        setMyMessage(<h3>WALLET DETECTED PLEASE LOGIN</h3>);
      else setMyMessage(<h3>WALLET NOT DETECTED</h3>);
      setMyDetails(ret);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onClick = async () => {
    console.log(dests);
    if (token == "trc" && tokenAddr) {
      dests.map(async e => {
        if (e.sendTo && e.amount)
          await transferTRC20(e.sendTo, e.amount, tokenAddr);
      })
    }
    else if (token == "trx") {
      dests.map(async e => {
        if (e.sendTo && e.amount)
          await transferTRX(e.sendTo, e.amount);
      })
    }
  };
  const handleCloseModal = () => {
    setModalClass(!modalClass);
    console.log('c')
  }
  return (
    <div>
      <div className="App">
        <div className={`Card ${modalClass ? 'hidden' : ''}`} id='card-nas'>
          <div className={`close-btn`} onClick={handleCloseModal}>
            <svg fill="#000000" width="20px" height="20px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.8,16l5.5-5.5c0.8-0.8,0.8-2,0-2.8l0,0C24,7.3,23.5,7,23,7c-0.5,0-1,0.2-1.4,0.6L16,13.2l-5.5-5.5  c-0.8-0.8-2.1-0.8-2.8,0C7.3,8,7,8.5,7,9.1s0.2,1,0.6,1.4l5.5,5.5l-5.5,5.5C7.3,21.9,7,22.4,7,23c0,0.5,0.2,1,0.6,1.4  C8,24.8,8.5,25,9,25c0.5,0,1-0.2,1.4-0.6l5.5-5.5l5.5,5.5c0.8,0.8,2.1,0.8,2.8,0c0.8-0.8,0.8-2.1,0-2.8L18.8,16z" />
            </svg>
          </div>
          <h1> Popeki Distributor : Never Scare Off Potential Investors Ever Again With Bad Token Distro - Input The Wallets You Want To Split The Tokens To And Disperse Your Tokens In Under 10 Seconds!  </h1>
          <h2>TIP: Did you know 78% of investors turn away from a token if they see a top holder holding more than 5% of the supply? </h2>
          <h2>The team recommends to ALWAYS disperse your tokens whenever you own more than 5% of a token.</h2>
          <div className="Logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="Stats">
            {myMessage}
            <h4>Account Name: {myDetails.name} </h4>
            <h4>Account Address: {myDetails.address}</h4>
          </div>
          <div className="formBody">
            <div className="checkboxs">
              <input type="radio" id="html" name="token" checked={token == "trx"} onClick={(e) => setToken(e.target.value)} value="trx" onChange={(e) => console.log(e)} />
              <label for="html">TRX</label><br></br>
              <input type="radio" id="css" name="token" checked={token == "trc"} onClick={(e) => setToken(e.target.value)} value="trc" />
              <label for="css">TRC20</label><br></br>
            </div>
            <div className="formContainer">
              <label htmlFor="tokenAddress">TRC20 Contract Address: </label>
              <input
                readOnly={token == "trx"}
                type="text"
                value={tokenAddr}
                onChange={(e) => {
                  setTokenAddr(e.target.value);
                }}
                id="tokenAddress"
              />
            </div>
            {
              dests.map((e, i) =>
                <Destination
                  item={i}
                  value={e}
                  setSendTo={setSendTo}
                  setAmount={setAmount}
                  expand={dests.length - i == 1 ? { desc: "+", func: onExpand } : (dests.length - i == 2 ? { desc: "-", func: onRemove } : null)}
                />
              )
            }

            <button className="sendBtn" onClick={onClick}>
              Send
            </button>
          </div>
        </div>
      </div>
      <div id="app">

      </div>


    </div>
  );
}

export default App;
