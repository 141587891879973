export const Destination = ({item, value, setSendTo, setAmount, expand}) => {
    console.log(item)
    return (
        <div className="destContainer">
            <div className="formContainer">
            <label htmlFor={`destination${item}`}>destination: </label>
            <input
                type="text"
                value={value.sendTo}
                onChange={(e) => {
                    if(e == value.sendTo) return
                    setSendTo(item, e.target.value);
                }}
                id={`destination${item}`}
            />
            </div>
            <div className="formContainer">
            <label htmlFor={`amount${item}`}>Amount: </label>
            <input
                type="number"
                value={value.amount}
                onChange={(e) => {
                    if(e == value.amount) return
                    setAmount(item, e.target.value);
                }}
                id={`amount${item}`}
            />
            </div>
            {expand ? <div className="expandContainer">
                <button className="expandBtn" onClick={expand.func}>{expand.desc}</button>
            </div> : <></>}
        </div>
    )
}